<template>
  <div>
    <!-- Popup Hotline -->
    <vue-final-modal v-model="modalHotline" content-class="modal-content" overlay-class="modal-overlay" classes="modal-container">
      <p class="title-popup">Kostenlose 24h Soforthotline</p><a class="popup-number" href="tel: 0800/7234114">
          <svg>
          <use xlink:href="@/assets/images/sprite.svg#icons8-ringer-volume"></use>
          </svg><span>0800 / 723 41 14</span></a>
      <p>Sie haben Frage und wünschen eine kostenlose Erstberatung per Telefon? Zögern Sie nicht uns unter oben genannter Nummer zu kontaktieren</p>
      <button @click="modalHotline = false" class="modal-close"><span></span><span></span><span></span></button>
    </vue-final-modal>
  </div>
</template>
<script>

export default {
  name: 'Footer',
  computed: {
    modalHotline: {
      get () {
        return this.$store.state.modalHotline
      },
      set (val) {
        this.$store.commit('setModalHotline', val)
      },
    }
  },
}
</script>